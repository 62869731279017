import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  console.log(url, config);
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  employee: {
    list: 'api/employee/list',
    details: (id: string) => `api/employee/${id}/details`,
    logs: (id: string) => `api/employee/${id}/logs`,
    triggerSync: 'api/employee/sync',
    getSyncStatus: (executionId: string) => `api/employee/sync/${executionId}`,
    evolutionDetails: (evolutionId: number) => `api/employee/evolution/${evolutionId}`,
    aadDetails: (aadId: string) => `api/employee/aad/${aadId}`,
    famlyDetails: (employeeId: string) => `api/employee/famly/${employeeId}`,
    eikDetails: (eikId: string) => `api/employee/eik/${eikId}`,
    didacDetails: (didacId: number) => `api/employee/didac/${didacId}`,
  },
  invoice: {
    list: 'api/invoice/list',
    getKindergartensLookup: 'api/invoice/organization-lookup',
    getKindergartenStats: 'api/invoice/stats',
  },
};
