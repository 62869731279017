import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import {
  EventType,
  PublicClientApplication,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import App from './App';
import { msalConfig } from './auth-config';
import { SplashScreen } from './components/loading-screen';

// ----------------------------------------------------------------------

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<SplashScreen />}>
          <App instance={msalInstance} />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
});
