import { UUID } from 'crypto';

export enum EmployeeSyncArea {
  Unknown,
  Evolution,
  ActiveDirectory,
  Famly,
  EIK,
  Documaster,
  Didac,
  XLedger,
}

export enum ExmployeeSyncStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export type IEmployeeTableFilterValue = string | string[];

export type IEmployeeTableFilters = {
  search: string;
  pageNumber: number;
  pageSize: number;
};

export type IEmployeeItem = {
  id: string;
  evoId: number;
  evoExtId: string;
  evoPhotoId: number | null;
  firstName: string;
  lastName: string;
  companyRole: string;
  lastSyncDate: Date;
  email: string | null;
  enabled: boolean | null;
  aadId: string | null;
  famlyId: string | null;
  eikId: string | null;
  didacId: number | null;
  departmentCode: string | null;
  departmentName: string | null;
};

export type IEmployeeEvolutionItem = {
  id: number;
  idExt: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  employedSince: string;
  employedUntil: string;
  homeOrgUnit: string;
  workDescription: string;
  employeeType: string;
  mainWorkPlace: string;
  leader: string;
  userType: string;
  companyRole: string;
  mobilePhone: string;
  modifiedOn: string;
  lastConfirmedAt: string;
  isActive: boolean;
};

export type IEmployeeAadItem = {
  id: UUID;
  accountEnabled: boolean;
  companyName: string;
  department: string;
  employeeId: string;
  employeeType: string;
  givenName: string;
  surname: string;
  jobTitle: string;
  officeLocation: string;
  birthday: string;
  hireDate: string;
  userPrincipalName: string;
  mobilePhone: string;
  extAttribute1: string;
  extAttribute2: string;
  extAttribute3: string;
  extAttribute4: string;
  extAttribute5: string;
  extAttribute6: string;
  extAttribute7: string;
  extAttribute8: string;
  extAttribute9: string;
  extAttribute10: string;
  extAttribute11: string;
  extAttribute12: string;
  extAttribute13: string;
  extAttribute14: string;
  extAttribute15: string;
};

export type IEmployeeFamlyItem = {
  id: UUID;
  groupId: UUID;
  groupName: string;
  siteId: UUID;
  siteName: string;
  roleId: UUID;
  title: string;
  email: string;
  fullName: string;
};

export type IEmployeeEikItem = {
  externalUserId: string;
  isDeactivated: boolean;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  organizations: IEmployeeEikOrganization[] | null;
};

export type IEmployeeEikOrganization = {
  personId: number;
  externalOrganizationId: string;
  externalRoleId: string;
  organizationId: number;
  organizationName: string;
};

export type IEmployeeDidacItem = {
  userId: number;
  foreignSystemKey: string;
  logonName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  archived: boolean;
  disabled: boolean;
  memberGroups: IEmployeeDidacGroupMember[];
};

export type IEmployeeDidacGroupMember = {
  groupId: number;
  groupName: string;
  referenceId: string;
  groupCategoryId: string;
};

export type IEmployeeSyncLog = {
  date: Date;
  scope: EmployeeSyncScope;
  logType: EmployeeSyncLogType;
  message: string;
  metadata: string;
};

export enum EmployeeSyncScope {
  None,
  Orchestrator,
  Evolution,
  AAD,
  Famly,
  EIK,
  Didac,
  Documaster,
  XLedger,
}

export enum EmployeeSyncLogType {
  Info,
  Warning,
  Error,
}
