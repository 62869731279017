import { Button, IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { fDateTime } from 'src/utils/format-time';

type Props = {
  name: string;
  lastSyncDate: Date | undefined;
  onReload: () => void;
  onSync: () => void;
};

export default function EmployeeDetailsToolbar({ name, lastSyncDate, onReload, onSync }: Props) {
  return (
    <Stack
      spacing={3}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        mb: { xs: 3, md: 5 },
      }}
    >
      <Stack spacing={1} direction="row" alignItems="flex-start">
        <IconButton component={RouterLink} href={paths.dashboard.employee.root}>
          <Iconify icon="eva:arrow-ios-back-fill" />
        </IconButton>

        <Stack spacing={0.5}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h4"> {name} </Typography>
            <Label variant="soft" color="success">
              Synced
            </Label>
          </Stack>

          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {fDateTime(lastSyncDate)}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        flexGrow={1}
        spacing={1.5}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          color="inherit"
          variant="outlined"
          onClick={onReload}
          startIcon={<Iconify icon="zondicons:reload" />}
        >
          Reload
        </Button>

        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="mdi:account-sync" />}
          onClick={onSync}
        >
          Sync
        </Button>
      </Stack>
    </Stack>
  );
}
