import { Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { LinearProgress, Container, Stack, Card, Box, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { getEmployeeSyncLogs } from 'src/api/employee';
import { IEmployeeSyncLog } from 'src/types/employee';
import { fDateTime } from 'src/utils/format-time';
import Iconify from 'src/components/iconify';

type Props = {
  id: string;
};

export default function EmployeeDetailsSyncDebug({ id }: Props) {
  const [loading, setLoading] = useState(false);
  const [hasMode, setHasMore] = useState(false);

  const [data, setData] = useState<IEmployeeSyncLog[]>([]);

  useEffect(() => {
    setLoading(true);
    getEmployeeSyncLogs(id).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }, [id]);

  return (
    <Card>
      {loading ? (
        <Container maxWidth="lg">
          <LinearProgress key="primary" color="primary" sx={{ mb: 2, width: 1 }} />
        </Container>
      ) : (
        <Stack
          spacing={3}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{ p: 3 }}
        >
          <Timeline
            sx={{
              p: 0,
              m: 0,
            }}
          >
            {data.map((item, index) => {
              const firstTimeline = index === 0;
              const lastTimeline = index === data.length - 1;

              return (
                <TimelineItem key={uniqueId()} position="alternate">
                  <TimelineSeparator>
                    <TimelineDot
                      color={(firstTimeline && 'primary') || 'grey'}
                      variant="outlined"
                    />
                    {lastTimeline ? null : <Iconify icon="eva:arrow-ios-back-fill" />}
                  </TimelineSeparator>

                  <TimelineContent>
                    <Typography variant="subtitle2">{item.message}</Typography>

                    <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                      {fDateTime(item.date)}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Stack>
      )}
    </Card>
  );
}
