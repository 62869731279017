import { useState, useCallback, useEffect } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
// hooks
// components
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
// types
import {
  IEmployeeItem,
  IEmployeeTableFilters,
  IEmployeeTableFilterValue,
} from 'src/types/employee';
//
import { searchEmployees } from 'src/api/employee';
import EmployeeTableRow from '../employee-table-row';
import EmployeeTableToolbar from '../employee-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'companyRole', label: 'Company Role', width: 220 },
  { id: 'department', label: 'Department', width: 260 },
  { id: 'departmentCode', label: 'Department Code', width: 220 },
  { id: 'lastSyncDate', label: 'Last Sync', width: 140 },
];

const defaultFilters: IEmployeeTableFilters = {
  search: '',
  pageNumber: 0,
  pageSize: 25,
};

// ----------------------------------------------------------------------

export default function EmployeeListView() {
  const table = useTable({
    defaultDense: true,
    defaultOrder: 'desc',
    defaultOrderBy: 'lastSyncDate',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const [tableData, setTableData] = useState<IEmployeeItem[]>([]);

  const [filters, setFilters] = useState(defaultFilters);

  const [dataLoading, setDataLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setDataLoading(true);
    const getEmployees = async () => {
      const response = await searchEmployees(filters);
      setTableData(response.data.items);
      setTotalCount(response.data.totalCount);
      setDataLoading(false);
    };

    getEmployees();
    return () => {};
  }, [filters]);

  const denseHeight = table.dense ? 52 : 72;

  const notFound = !tableData.length || !tableData.length;

  const handlePageChange = useCallback((event: React.MouseEvent | null, page: number) => {
    setFilters((prevState: IEmployeeTableFilters) => ({
      ...prevState,
      pageNumber: page,
    }));
  }, []);

  const handlChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prevState: IEmployeeTableFilters) => ({
      ...prevState,
      pageNumber: 0,
      pageSize: parseInt(event.target.value, 10),
    }));
  }, []);

  const handleFilters = useCallback((name: string, value: IEmployeeTableFilterValue) => {
    setFilters((prevState: IEmployeeTableFilters) => ({
      ...prevState,
      pageNumber: 0,
      [name]: value,
    }));
  }, []);

  const handleDetailsRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.employee.details(id));
    },
    [router]
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Card>
        <EmployeeTableToolbar onFilters={handleFilters} />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom order={table.order} orderBy={table.orderBy} headLabel={TABLE_HEAD} />

              <TableBody>
                {dataLoading ? (
                  [...Array(filters.pageSize)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: denseHeight }} />
                  ))
                ) : (
                  <>
                    {tableData.map((row) => (
                      <EmployeeTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => handleDetailsRow(row.id)}
                      />
                    ))}
                  </>
                )}

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(filters.pageNumber, filters.pageSize, tableData.length)}
                /> */}

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalCount}
          page={filters.pageNumber}
          rowsPerPage={filters.pageSize}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}
