import { format, getTime, formatDistanceToNow } from 'date-fns';
import { Dayjs } from 'dayjs';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fApiDate(date: InputValue) {
  return fDate(date, 'yyyy-MM-dd');
}

export function fDayjsDate(date: Dayjs | null | undefined) {
  if (!date) {
    return '';
  }

  return date.format('YYYY-MM-DD');
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
