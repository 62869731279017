// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    employee: {
      root: `${ROOTS.DASHBOARD}/employee`,
      details: (id: string) => `${ROOTS.DASHBOARD}/employee/${id}/details`,
    },
    organization: `${ROOTS.DASHBOARD}/organization`,
    invoice: `${ROOTS.DASHBOARD}/invoice`,
    report: {
      root: `${ROOTS.DASHBOARD}/report`,
      sync: `${ROOTS.DASHBOARD}/report/sync`,
      reconciliation: `${ROOTS.DASHBOARD}/report/reconciliation`,
    },
  },
};
