import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  AccountInfo,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
// routes
// hooks
// auth
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { getAdUser, getAdUserPicture } from 'src/api/graph';
import { loginRequest } from 'src/auth-config';

export type UserGraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
};

function LogoutHandler(instance: IPublicClientApplication) {
  const logoutRequest = {
    account: instance.getActiveAccount(),
    postLogoutRedirectUri: '/',
  };
  instance.logoutRedirect(logoutRequest);
}

export default function AccountPopover() {
  const popover = usePopover();

  const { instance, inProgress } = useMsal();

  const [userGraphData, setUserGraphData] = useState<UserGraphData | null>(null);
  const [userImageUrl, setUserImageUrl] = useState<string>('');

  useEffect(() => {
    if (!userGraphData && inProgress === InteractionStatus.None) {
      getAdUser(instance)
        .then((response) => setUserGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
      getAdUserPicture(instance)
        .then((resp) => {
          if (!resp) {
            return;
          }
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(resp);
          setUserImageUrl(blobUrl);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, userGraphData, instance]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={userImageUrl}
          alt={userGraphData?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userGraphData?.displayName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userGraphData?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userGraphData?.mail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => LogoutHandler(instance)}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
