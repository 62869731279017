// utils
import axios, { endpoints } from 'src/utils/axios';
// types
import {
  IEmployeeItem,
  IEmployeeSyncLog,
  IEmployeeTableFilters,
  IEmployeeFamlyItem,
  IEmployeeEikItem,
  IEmployeeDidacItem,
  IEmployeeEvolutionItem,
  IEmployeeAadItem,
  EmployeeSyncArea,
  ExmployeeSyncStatus,
} from 'src/types/employee';
import { IListQueryResponse } from './responses';

export async function searchEmployees(filters: IEmployeeTableFilters) {
  return axios.post<IListQueryResponse<IEmployeeItem>>(endpoints.employee.list, filters);
}

export async function getEmployeeDetails(id: string) {
  return axios.get<IEmployeeItem>(endpoints.employee.details(id));
}

export async function getEmployeeSyncLogs(id: string) {
  return axios.get<IEmployeeSyncLog[]>(endpoints.employee.logs(id));
}

export async function getEmployeeEvolutionDetails(evolutionId: number) {
  return axios.get<IEmployeeEvolutionItem>(endpoints.employee.evolutionDetails(evolutionId));
}

export async function getEmployeeAadDetails(aadId: string) {
  return axios.get<IEmployeeAadItem>(endpoints.employee.aadDetails(aadId));
}

export async function getEmployeeFamlyDetails(employeeId: string) {
  return axios.get<IEmployeeFamlyItem>(endpoints.employee.famlyDetails(employeeId));
}

export async function getEmployeeEikDetails(eikId: string) {
  return axios.get<IEmployeeEikItem>(endpoints.employee.eikDetails(eikId));
}

export async function getEmployeeDidacDetails(didacId: number) {
  return axios.get<IEmployeeDidacItem>(endpoints.employee.didacDetails(didacId));
}

export async function getEmployeeSyncStatus(executionId: string) {
  return axios.get<ExmployeeSyncStatus>(endpoints.employee.getSyncStatus(executionId));
}

export async function triggerEmployeeSync(id: string, syncAreas: EmployeeSyncArea[]) {
  return axios.post<string>(endpoints.employee.triggerSync, { employeeId: id, syncAreas });
}
