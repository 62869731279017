import { useCallback, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
// types
import { IEmployeeTableFilters, IEmployeeTableFilterValue } from 'src/types/employee';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  // filters: IEmployeeTableFilters;
  onFilters: (name: string, value: IEmployeeTableFilterValue) => void;
};

export default function EmployeeTableToolbar({ onFilters }: Props) {
  const [searchValue, setSearchValue] = useState('');

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.value.length === 0 ||
        event.target.value.length > 3 ||
        event.target.value.length < searchValue.length
      ) {
        onFilters('search', event.target.value);
      }
      setSearchValue(event.target.value);
    },
    [onFilters, searchValue.length]
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={searchValue}
          onChange={handleFilterSearch}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
