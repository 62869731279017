import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import EmployeeDetailPage from 'src/pages/dashboard/employee/details';

// ----------------------------------------------------------------------

const DashboardPage = lazy(() => import('src/pages/dashboard/dashboard'));
const EmployeePage = lazy(() => import('src/pages/dashboard/employee/list'));
const InvoicePage = lazy(() => import('src/pages/dashboard/invoice/list'));
const PageFour = lazy(() => import('src/pages/dashboard/four'));
const PageFive = lazy(() => import('src/pages/dashboard/five'));
const PageSix = lazy(() => import('src/pages/dashboard/six'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      {
        path: 'employee',
        children: [
          { element: <EmployeePage />, index: true },
          { path: ':id/details', element: <EmployeeDetailPage /> },
        ],
      },
      // { path: 'organization', element: <PageThree /> },
      { path: 'invoice', element: <InvoicePage /> },
      {
        path: 'report',
        children: [
          { element: <PageFour />, index: true },
          { path: 'sync', element: <PageFive /> },
          { path: 'reconciliation', element: <PageSix /> },
        ],
      },
    ],
  },
];
