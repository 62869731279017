import { IPublicClientApplication } from '@azure/msal-browser';
import { graphConfig, loginRequest } from 'src/auth-config';

export async function getAdUser(msalInstance: IPublicClientApplication) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((resp) => resp.json())
    .catch((error) => console.log(error));
}

export async function getAdUserPicture(msalInstance: IPublicClientApplication) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers,
    response: {
      type: 'blob',
    },
  };

  return fetch(graphConfig.graphMePhotoEndpoint, options)
    .then((resp) => resp.blob())
    .catch((error) => console.log(error));
}
