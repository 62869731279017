// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import { useNavigate } from 'react-router-dom';
import { lazy } from 'react';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';

import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// auth
import { IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/NavigationClient';
import AuthSSOLayout from './layouts/auth/sso';
import { MotionLazy } from './components/animate/motion-lazy';
import { SnackbarProvider } from './components/snackbar';

// ----------------------------------------------------------------------

const LoginSSOPage = lazy(() => import('src/pages/auth/login'));

type Props = {
  instance: IPublicClientApplication;
};

export default function App({ instance }: Props) {
  useScrollToTop();

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={instance}>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light',
          themeDirection: 'ltr',
          themeContrast: 'default',
          themeLayout: 'horizontal',
          themeColorPresets: 'red',
          themeStretch: true,
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <SettingsDrawer />
              <ProgressBar />

              <AuthenticatedTemplate>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Router />
                </LocalizationProvider>
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <AuthSSOLayout>
                  <LoginSSOPage />
                </AuthSSOLayout>
              </UnauthenticatedTemplate>
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </MsalProvider>
  );
}
