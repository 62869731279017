// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// @mui
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
// hooks
// types
import { IEmployeeItem } from 'src/types/employee';
import { getAvatarColor } from 'src/utils/commons';

// components
//

// ----------------------------------------------------------------------
dayjs.extend(timezone);
dayjs.extend(utc);

type Props = {
  row: IEmployeeItem;
  onSelectRow: (id: string) => void;
};

export default function EmployeeTableRow({ row, onSelectRow }: Props) {
  const {
    id,
    firstName,
    lastName,
    email,
    evoId,
    evoPhotoId,
    companyRole,
    departmentCode,
    departmentName,
    lastSyncDate,
  } = row;

  const syncDate = dayjs.tz(dayjs.utc(lastSyncDate), dayjs.tz.guess()).format('DD-MM-YYYY HH:mm');
  // TODO const avatarUrl = `https://espira.no.gipsonline.com/files/gips_userfile_thumbnails/employee/${evoId}/${evoPhotoId}`;

  return (
    <TableRow hover onClick={() => onSelectRow(id)} key={evoId}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          sx={{
            backgroundColor: getAvatarColor(firstName),
            mr: 2,
            cursor: 'pointer',
          }}
          // TODO src={avatarUrl}
        >
          {firstName.substring(0, 1)}
          {lastName.substring(0, 1)}
        </Avatar>
        <ListItemText
          primary={`${firstName} ${lastName}`}
          secondary={email}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{companyRole}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{departmentName}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{departmentCode}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{syncDate}</TableCell>
    </TableRow>
  );
}
