import { useParams } from 'src/routes/hooks';
import { EmployeeDetailsView } from 'src/sections/employee/view';

export default function EmployeeDetailPage() {
  const params = useParams();

  const { id } = params;

  return <EmployeeDetailsView id={`${id}`} />;
}
